<template>
  <div class="card mb-3">
    <div class="card-body p-3">
      <div class="chart">
        <canvas ref="chartCanvas" id="productos" class="chart-canvas" height="100px"></canvas>
      </div>
    </div>
  </div>
</template>

<script src="path/to/chartjs/dist/chart.umd.js"></script>
<script>
import { ref, onMounted, watchEffect } from 'vue';
import Chart from 'chart.js/auto';

export default {
  props: ['labels', 'data'],

  setup(props) {
    const chart = ref(null);

    const getRandomColor = () => {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);

      // Formatear el color en formato RGB
      const rgbColor = `rgb(${r}, ${g}, ${b})`;
      return rgbColor;
    };

    onMounted(() => {
      renderChart();
    });

    watchEffect(() => {
      renderChart();
    });

    function renderChart() {
      const canvas = document.getElementById('productos');
      const backgroundColors = props.labels.map(() => getRandomColor());
      if (!canvas) {
        // El elemento no está disponible en el DOM todavía
        return;
      }

      const ctx = canvas.getContext('2d');

      if (!ctx) {
        // El contexto no está disponible
        return;
      }

      if (chart.value) {
        chart.value.destroy();
      }

      chart.value = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: props.labels,
          datasets: [
            {
              data: props.data,
              label: 'Producto mas vendido',
              backgroundColor: backgroundColors,
              borderColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }

    return {
      renderChart,
    };
  },
};
</script>
