<template>
  <div class="container mt-3">

    <b-alert :show="showError" class="position-fixed fixed-top m-0 rounded-0" style="z-index: 2000;" variant="warning">
      {{this.errorText}}
    </b-alert>

    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div>
            <form role="form">
              <slot>
                <!-- CSRF gets injected into this slot -->
              </slot>
              <!-- form fields here -->
            </form>
          </div>
          <b-container fluid class="mt-3">
            <b-row>
              <b-col cols="11" md="8" style="margin: 15px">
                <b-col sm="4" class="mt-0 ml-3">
                  <label for="input-none">Código de barras</label>
                </b-col>
                <b-col sm="3">
                  <b-form-input class="no-spinners" type="number" size="sm" ref="here" id="input-none" v-model="inputContent" @keyup.enter="onProductInput" :state="null" placeholder="Escanee un código..."></b-form-input>
                </b-col>
              </b-col>
              <b-col style="margin: 15px" cols="6" md="3"><b-card bg-variant="light" class="text-center">
                  <b-card-text :class="{ 'red': this.items.some(item => item.quantity > item.stock) }"> Total: $ <b>{{totalPrice.toFixed(2)}}</b></b-card-text>
                </b-card></b-col>
            </b-row>

          </b-container>
          <div class="m-4" v-if="this.items.length >0">
            <b-table responsive striped hover :bordered="true" :fixed="true" :items="items" :fields="selected==1 ? fields : selected==0 ? fieldsDiscount : fieldsDiscountTransfer" style="border-radius: 10px; border: 1px solid #E9E9E9; ">
              <template #cell(actions)="row">
                <a size="sm" @click="deleteItem(row.item)"><i class="material-icons opacity-10" style="padding-top: 5px">delete</i>
                </a>
              </template>

              <template #cell(quantity)="row">
                <div class="text-center mx-auto">
                  <b-form-input v-b-tooltip.hover.right="tipMethod(row)" type="number" style="width: 90px; margin-left: 20px" v-model="row.value" min="1" max="200  " @change="changeRowQty(row)" size="sm" :state="checkQtyState(row)" placeholder="Cant."></b-form-input>
                </div>
              </template>
            </b-table>
          </div>
          <div class="m-4 text-center" v-else>
            <p>No hay ítems para mostrar</p>
          </div>

          <div class="row m-3">
            <div class="row" v-if="this.items.length >0">
              <div class="pr-2" style="text-align: left">
                <b-form-group label="Método de pago">
                  <b-form-radio-group @change="changePaymentMethod" plain id="radio-group-1" v-model="selected" :options="options" name="radio-inline"></b-form-radio-group>
                </b-form-group>
              </div>
            </div>
            <div class="pr-2" style="text-align: right; margin-right: 20px">
              <b-button :disabled="items.length==0 || this.items.some(item => item.quantity > item.stock)" variant="outline-secondary" @click="venderProducto">
                Guardar venta
              </b-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inputContent: "",
      loading: false,
      showError: false,
      errorText: "",
      totalPrice: 0,
      showDiscount: false,
      showDiscountTransfer: false,
      totalPriceDiscount: 0,
      discount: 0,

      fields: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "price",
          label: "Precio",
          tdClass: "text-center", // Centra el contenido a la derecha
          formatter: (value) => `$${value.toFixed(2)}`,
        },
        {
          key: "quantity",
          label: "Cantidad",
          tdClass: "text-center",
          thStyle: { width: "15%" },
          // Variant applies to the whole column, including the header and footer
          //variant: 'danger'
        },

        {
          key: "actions",
          label: "",
          tdClass: "text-center",
          thStyle: { width: "10%" },
        },
      ],

      fieldsDiscount: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "priceDiscounted",
          label: "Precio",
          tdClass: "text-center", // Centra el contenido a la derecha
          formatter: (value) => `$${value.toFixed(2)}`,
        },
        {
          key: "quantity",
          label: "Cantidad",
          tdClass: "text-center",
          thStyle: { width: "15%" },
          // Variant applies to the whole column, including the header and footer
          //variant: 'danger'
        },

        {
          key: "actions",
          label: "",
          tdClass: "text-center",
          thStyle: { width: "10%" },
        },
      ],

      fieldsDiscountTransfer: [
        {
          key: "name",
          label: "Producto",
        },
        {
          key: "priceDiscountedTransf",
          label: "Precio",
          tdClass: "text-center", // Centra el contenido a la derecha
          formatter: (value) => `$${value.toFixed(2)}`,
        },
        {
          key: "quantity",
          label: "Cantidad",
          tdClass: "text-center",
          thStyle: { width: "15%" },
          // Variant applies to the whole column, including the header and footer
          //variant: 'danger'
        },

        {
          key: "actions",
          label: "",
          tdClass: "text-center",
          thStyle: { width: "10%" },
        },
      ],

      options: [
        { text: "Tarjeta", value: 1 },
        { text: "Efectivo", value: 0 },
        { text: "Transferencia bancaria", value: 2 },
      ],
      selected: 1,
      items: [],
    };
  },

  methods: {
    async onProductInput() {
      this.showError = false;
      this.loading = true;
      const response = axios
        .get("/buscar-producto?codigo=" + this.inputContent)
        .then((res) => {
          this.loading = false;
          if (res.status == 200) {
            if (res.data != null) {
              if (res.data.status == "error") {
                this.inputContent = "";
                this.errorText = res.data.message;
                this.showError = true;
                setTimeout(() => {
                  this.showError = false;
                }, 3000);
              } else {
                var data = res.data;
                this.inputContent = "";

                if (data.precioProductoSeleccionado != null) {
                  var product = {
                    code: data.codigoProductoSeleccionado,
                    name: data.nombreProductoSeleccionado,
                    price: data.precioProductoSeleccionado,
                    priceDiscounted: data.precioProductoSeleccionado * 0.85,
                    priceDiscountedTransf:
                      data.precioProductoSeleccionado * 0.9,
                    stock: data.stockProductoSeleccionado,
                  };

                  console.log(product);

                  var precioProductoDiscount =
                    Math.ceil(product.priceDiscounted / 10) * 10;
                  var precioProductoDiscountTransf =
                    Math.ceil(product.priceDiscountedTransf / 10) * 10;

                  product.priceDiscounted = precioProductoDiscount;
                  product.priceDiscountedTransf = precioProductoDiscountTransf;

                  console.log(
                    "Precio Producto Discount:",
                    product.priceDiscounted
                  );
                  console.log(
                    "Precio Producto Discount Transf:",
                    product.priceDiscountedTransf
                  );

                  this.addItem(product);
                } else {
                  this.errorText = "El producto no tiene precio total cargado";
                  this.showError = true;
                  setTimeout(() => {
                    this.showError = false;
                  }, 3000);
                }
              }
            } else {
              this.inputContent = "";
              this.errorText = res.data.message;
              this.showError = true;
              setTimeout(() => {
                this.showError = false;
              }, 3000);
            }
          } else {
            this.inputContent = "";
            this.errorText = "Ocurrió un error al buscar el producto";
            this.showError = true;
            setTimeout(() => {
              this.showError = false;
            }, 3000);
          }

          /*this.$swal({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          }); */
        })
        .catch((err) => {
          console.log(err);
        });

      this.$refs.here.focus();
    },

    changePaymentMethod(m) {
      if (m == 1) {
        this.showDiscount = true;
      } else if (m == 2) {
        this.showDiscountTransfer = true;
      } else {
        this.showDiscount = false;
        this.showDiscountTransfer = false;
      }
      this.updateTotalPrice();
    },

    deleteItem(item) {
      // Encontrar el índice del elemento con el código 'B'
      let index = this.items.findIndex(
        (itemToDelete) => itemToDelete.code === item.code
      );

      // Eliminar el elemento del array
      if (index !== -1) {
        this.items.splice(index, 1);
        this.updateTotalPrice();
      }
    },

    addItem(product) {
      if (this.items.length > 0) {
        // Search if product exists in items
        var itemFoundIndex = this.items.findIndex(
          (existingProduct) => existingProduct.code == product.code
        );

        if (itemFoundIndex !== -1) {
          var quantityCalculated =
            parseInt(this.items[itemFoundIndex].quantity) + 1;
          if (product.stock >= parseInt(quantityCalculated)) {
            this.items[itemFoundIndex].quantity++;
          } else {
            this.showStockError();
          }
        } else {
          product.quantity = 1;
          this.items.push(product);
        }
      } else {
        product.quantity = 1;

        if (product.stock >= product.quantity) {
          this.items.push(product);
        } else {
          this.showStockError();
        }
      }

      this.updateTotalPrice();
    },

    showStockError() {
      this.errorText = "El producto ingresado no tiene stock suficiente";
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 4000);
    },

    updateTotalPrice() {
      if (this.selected == 1) {
        this.totalPrice = this.items.reduce((total, item) => {
          return total + item.price * item.quantity;
        }, 0);
      } else if (this.selected == 0) {
        this.totalPrice = this.items.reduce((total, item) => {
          return total + item.priceDiscounted * item.quantity;
        }, 0);
      } else if (this.selected == 2) {
        this.totalPrice = this.items.reduce((total, item) => {
          return total + item.priceDiscountedTransf * item.quantity;
        }, 0);
      }

      //this.discount = this.totalPrice * 0.15;
    },

    async venderProducto() {
      this.loading = true;
      const csrfToken = document.head.querySelector(
        'meta[name="csrf-token"]'
      ).content;

      const itemsToSend = this.items.map((item) => ({
        codigo_id: item.code,
        cantidad: item.quantity,
      }));

      const dataToSend = {
        contado: this.selected == 0,
        transferencia: this.selected == 2,
        items: itemsToSend,
      };

      console.log(dataToSend);

      try {
        const response = await axios.post("/guardar-venta", dataToSend, {
          headers: {
            "X-CSRF-TOKEN": csrfToken,
            "Content-Type": "application/json", // Agrega este encabezado si es necesario
          },
        });

        this.loading = false;

        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Venta realizada",
          showConfirmButton: false,
          timer: 1500,
        });

        this.items = [];
        this.totalPrice = 0;
        this.selected = 1;
        this.$refs.here.focus();
      } catch (error) {
        window.location.href = "/sign-in";
      }
    },

    changeRowQty(row) {
      var item = row.item;
      var itemFoundIndex = this.items.findIndex(
        (existingProduct) => existingProduct.code == item.code
      );

      if (itemFoundIndex !== -1) {
        var quantityCalculated = (this.items[itemFoundIndex].quantity =
          row.value);
        if (item.stock >= quantityCalculated) {
          this.items[itemFoundIndex].quantity = row.value;
        } else {
          this.showStockError();
        }
      }
      this.updateTotalPrice();
    },

    checkQtyState(row) {
      if (row.value > row.item.stock) {
        return false;
      } else {
        return null;
      }
    },

    tipMethod(row) {
      if (row.value > row.item.stock) {
        return (
          "El stock disponible del producto es de " +
          row.item.stock +
          " unidades"
        );
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.$refs.here.focus();
  },
};
</script>

<style scoped>
.alert-warning {
  color: #975400 !important;
  background-color: #fee8cc !important;
  border-color: #feddb3 !important;
}

.no-spinners {
  /* Oculta las flechas del input tipo número */
  -moz-appearance: textfield;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Para Firefox */
.no-spinners[type="number"] {
  -moz-appearance: textfield;
}

.btn-outline-primary:focus {
  border: none; /* Quitar el borde después de ser presionado */
  box-shadow: none; /* Quitar la sombra al ser presionado */
}
.btn-outline-primary:hover {
}

.red {
  color: rgb(212, 133, 48); /* Color rojo */
}
</style>
