require('./bootstrap');

//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BootstrapVue } from 'bootstrap-vue';
import VueBarcode from 'vue-barcode';
import VueHtml2pdf from 'vue-html2pdf';
import VueSweetalert2 from 'vue-sweetalert2';

//import 'sweetalert2/dist/sweetalert2.min.css';

window.Vue = require('vue').default;
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);

Vue.component('picking', require('./components/Picking.vue').default);
Vue.component('barcode-gen', require('./components/PrintPdf.vue').default);
Vue.component('chartventas', require('./components/ChartVenta.vue').default);
Vue.component('chartproductos', require('./components/chartProductos.vue').default);
Vue.component('barcode', VueBarcode);
Vue.component('vue-html2pdf', VueHtml2pdf);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
