<template>
    <div class="card mb-3">
      <div class="card-body p-3">
        <div class="chart">
          <canvas ref="chartCanvas" id="chartCanvas" class="chart-canvas" height="150px"></canvas>
        </div>
      </div>
    </div>
</template>
<script src="path/to/chartjs/dist/chart.umd.js"></script>
<script>
import { ref, onMounted, watchEffect } from 'vue';
 import Chart from 'chart.js/auto';


export default {
  props: ['labels', 'data'],
  
  setup(props) {
    const chart = ref(null);

    onMounted(() => {
      renderChart();
    });
    
    watchEffect(() => {
      renderChart();
    });

    function renderChart() {
      const canvas = document.getElementById('chartCanvas');
      
      if (!canvas) {
        // El elemento no está disponible en el DOM todavía
        return;
      }

      const ctx = canvas.getContext('2d');

      if (!ctx) {
        // El contexto no está disponible
        return;
      }

      if (chart.value) {
        chart.value.destroy();
      }

      chart.value = new Chart(ctx, {
        type: 'line',
        data: {
          labels: props.labels,
          datasets: [{
            label: 'Ventas por día',
            data: props.data,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgb(255,192,203)',
            borderColor: 'rgb(255,192,203)',
            borderWidth: 1,
          }],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return '$' + value;
                },
              },
            },
          },
        },
      });
      
    }

    return {
      
      renderChart,
    };
  },
};
</script>